<template>
  <div class="se-pre-con" v-if="show"></div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  },
}
</script>

<style>
.se-pre-con {
  position: fixed;
  left: 0px;
  opacity: 0.6;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: url(/assets/img/preloader.gif) center no-repeat #fff;
}
</style>