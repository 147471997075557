<template>
  <div class="container-fluid header">
    <div class="container">
      <div class="row">
        <div class="col-md-2 col-sm-2 header-box-logo">
          <v-link className="nav-link" href="/">
            <img src="/assets/img/logo.jpg" alt="Ticket Experience"/>
          </v-link>
        </div>
        <div class="col-md-7 col-sm-7 header-box-menu">
          <ul>


            <li class="a-calendar">
              <v-link className="nav-link" href="/calendario/">calendário</v-link>
            </li>

            <li class="a-conta" v-if="user == ''">
              <v-link className="nav-link" href="/acesso-atleta/">acesso atleta</v-link>
            </li>

            <li class="a-conta2" v-else>
              Olá, {{ user.first_name }}<br />
              <v-link href="/minha-conta/">minha conta</v-link>
            </li>

            <li class="a-carinho" v-if="user != ''">
              <span class="badge" v-if="carrinho != 0">{{carrinho}}</span>
              <span class="glyphicon glyphicon-shopping-cart"></span>
              <v-link className="nav-link" href="/carrinho/">Meu Carrinho</v-link>
            </li>

            <li class="logout" v-if="user != ''">
              <span class="glyphicon glyphicon-off"></span>
              <v-link href="/sair/" @click.prevent="logout">sair</v-link>
            </li>

            <li class="a-evento" v-if="user == ''">
              <v-link className="nav-link" href="/cadastre-se/">faça seu cadastro</v-link>
            </li>

          </ul>
        </div>
        <div class="col-md-3 col-sm-3 header-box-redes">
          <p>Nos acompanhe<br /><b>NAS REDES SOCIAIS</b></p>
          <p>
            <a :href="FACEBOOK_LINK" target="_blank">
              <img src="/assets/img/ico-face.jpg" alt="Facebook"/>
            </a>
            <a :href="INSTAGRAN_LINK" target="_blank">
              <img src="/assets/img/ico-instagram.jpg" alt="Instagram"/>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VLink from "../components/VLink.vue";
import Auth from "@/api/Auth";
import Carrinho from "../api/Carrinho";

export default {
  name: "HeaderHome",
  components: {
    VLink,
  },
  data() {
    return {
      FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      INSTAGRAN_LINK: process.env.VUE_APP_INSTAGRAN_LINK,
      user: "",
      carrinho:null,
    };
  },
  methods: {
    logout() {
      Auth.logout();
      this.$router.push('/')
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.user = Auth.user();
    window.addEventListener('user-logged-event', (event) => {
      this.user = event.detail.user;
    });
    window.addEventListener('user-logout-event', () => {
      this.user = '';
    });

    window.addEventListener('user-carrinho', () => {
      setTimeout(() => {
        if(this.user != ''){
          let Itens =  Carrinho.get_itens();
          this.carrinho = Itens.length;
        }
      },500);
    });

    if(this.user != ''){
     let Itens =  Carrinho.get_itens();
     this.carrinho = Itens.length;
    }
  },
};
</script>
<style scoped>

li.logout{
  padding: 12px 0 8px 40px;
}

.logout .glyphicon {
  color: #d8c1c7;
  font-size: 20px;
  margin-top: -4px;
  margin-right: 7px;
}

.a-carinho .glyphicon{
  color: #d8c1c7;
  font-size: 20px;
  margin-top: -4px;
  margin-right: 7px;
}


li.logout a{
  position: absolute;
}
.header-box-menu li a:hover{
  color: #d9534f;
}
.badge {
  position: absolute;
  border-radius: 100%;
  background: #cf1e24;
  color: white;
  z-index: 1;
  margin-left: 13px;
  margin-top: -13px;
  font-size: 9px;
  padding: 4px 5px 3px;
}


@media (max-width: 650px) {
  .header-box-menu ul li.logout {
    text-align: end;
    width: 72px;
    margin-top: 10px;
  }
}
</style>